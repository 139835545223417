import React, { useEffect, useState,useRef} from 'react';
import socketIOClient from 'socket.io-client';
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import '../css/widget.css';
import {Input,Button, Card,CardBody,CardText,CardTitle,CardSubtitle,Spinner} from 'reactstrap';
// import QRCodeReaderModal from './qrcodereader';
//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FaCheckCircle, FaTimesCircle,FaBarcode, FaPaperPlane, FaEnvelopeOpen, FaEnvelopeOpenText, FaUserCheck,FaIdCard,FaCalculator,FaHandshakeSlash,} from 'react-icons/fa';
import Cookies from 'js-cookie';

const ENDPOINT = process.env.REACT_APP_API_URL;
console.log(ENDPOINT);
function IDClient(props) {





  //const { executeRecaptcha } = useGoogleReCaptcha();
  const [uniqueId, setUniqueId] = useState(null);
  const [inProgress,setInProgress]=useState(false);
  const [input,setInput]= useState(false);
  const [blockedDomains, setBlockedDomains]=useState([]);
  const [allowedDomains, setAllowedDomains]=useState([]);
  const [email,setEmail]= useState("");
  const [emailnotValid,setemailnotValid]=useState(null);
  const [isValid,setisValid]=useState(null);
  const [message,setMessage]=useState(null);
  const [initialized, setInitialized]= useState(null);
  const [icon, setIcon] = useState(null);
  const [messages,setMessages]=useState([]);
  const languageRef =useRef("en");
  const emailMessagesRef = useRef(null);
  //const [recaptchaResponse, setRecaptchaResponse] = useState('');
 // const [token, setToken] = useState('');

//   useEffect(() => {
//     if (props.hoverColor) {
//         const styleSheet = document.styleSheets[0]; // Get the first stylesheet

//         // Find the index of the existing .widget-button:hover rule
//         const ruleIndex = Array.from(styleSheet.cssRules).findIndex(
//             rule => rule.selectorText === '.widget-button:hover'
//         );

//         // If the rule exists, delete it
//         if (ruleIndex !== -1) {
//             styleSheet.deleteRule(ruleIndex);
//         }

//         // Create and insert the new rule
//         const newRule = `.widget-button:hover { background-color: ${props.hoverColor}; border-color: ${props.hoverColor}; }`;
//         styleSheet.insertRule(newRule, styleSheet.cssRules.length);
//     }
// }, [props.hoverColor]);


  // function validateEmail(email) {
  //   // Regular expression for a basic email format validation
  //   const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
  //   return emailRegex.test(email);
  // }

  const iconMap = {
    FaCheckCircle: <FaCheckCircle size="2em" style={{  marginTop: '10px', fontSize: '2em' }} />,
    FaTimesCircle: <FaTimesCircle size="2em" style={{  marginTop: '10px', fontSize: '2em' }} />,
    FaBarcode: <FaBarcode size="2em" style={{ marginTop: '10px', fontSize: '2em' }} />,
    FaPaperPlane: <FaEnvelopeOpen size="2em" style={{  marginTop: '10px', fontSize: '2em' }} />,
    FaEnvelopeOpen: <FaEnvelopeOpen size="2em" style={{  marginTop: '10px', fontSize: '2em' }} />,
    FaEnvelopeOpenText: <FaEnvelopeOpenText size="2em" style={{  marginTop: '10px', fontSize: '2em' }} />,
    FaUserCheck: <FaUserCheck size="2em" style={{  marginTop: '10px', fontSize: '2em' }} />,
    FaIdCard: <FaIdCard size="2em" style={{ marginTop: '10px', fontSize: '2em' }} />,
    FaCalculator: <FaCalculator size="2em" style={{ marginTop: '10px', fontSize: '2em' }} />,
    FaHandshakeSlash: <FaHandshakeSlash size="2em" style={{ marginTop: '10px', fontSize: '2em' }} />,
  };
  

  function validateEmail(email) {
    // Regular expression for a basic email format validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    console.log("Allowed Domains", allowedDomains);
  
    // First, check if the email matches the basic email format
    if (!emailRegex.test(email)) {
        return {
            isValid: false,
            message: emailMessagesRef.current.invalidemailformat
        }; // Email does not match the basic format
    }
  
    // Check if the email domain is in the blocked domains list
    const emailDomain = email.split('@')[1];
   
    const isBlocked = blockedDomains && blockedDomains.length > 0 && blockedDomains.some(blockedDomain => {
        return emailDomain === blockedDomain || emailDomain.endsWith(`.${blockedDomain}`);
    });
  
    if (isBlocked) {
        console.log("Email is from a blocked domain");
        return {
            isValid: false,
            message: `${emailMessagesRef.current.emaildomainnotallowed} ${blockedDomains.join(', ')}`
        };
    }
  
    // Check if the extracted domain is in the list of allowed domains
    if (allowedDomains && allowedDomains.length > 0) {
        const isAllowed = allowedDomains.some(allowedDomain => {
            return emailDomain === allowedDomain || emailDomain.endsWith(`.${allowedDomain}`);
        });

        if (!isAllowed) {
            return {
                isValid: false,
                message: `${emailMessagesRef.current.emaildomainallowed} ${allowedDomains.join(', ')}`
            };
        }
    }
  
    return {
        isValid: true,
        message: ""
    }; // Email is allowed
}
  
  // Example usage:
 // const blockedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];




  function sanitizeEmail(email) {
    // Remove any non-alphanumeric characters except for @ and .
    const sanitizedEmail = email.replace(/[^a-zA-Z0-9@.]/g, '');
  
    return sanitizedEmail;
  }


  const initializeWidget = async (apiKey) => {
    console.log("running widget");
      //if (executeRecaptcha) {
        // const token = await executeRecaptcha('Authenticate');
        
        axios.post(ENDPOINT + '/authenticate',{ "language": languageRef.current}, {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
          }
        })
          .then(response => {
            console.log(response.data);
            setInitialized(response.data.response.verified)
            if(response.data.response.verified === true){
              if(response.data.response.openid){
                sessionStorage.setItem('openid', response.data.response.openid);
              }

              if(response.data.response.emailMessages){

                emailMessagesRef.current = response.data.response.emailMessages;



              }
              if (response.data.response.color) {
                
                document.documentElement.style.setProperty('--custom-color', response.data.response.color);
                
              }
              if (response.data.response.backgroundColor) {
                // setBackgroundColor(response.data.backgroundColor);
                document.documentElement.style.setProperty('--custom-bg-color', response.data.response.backgroundColor);
                
              }

              
            if(response.data.response.input){
              setInput(response.data.response.input)
            }
            if(response.data.response.blockeddomains){
              setBlockedDomains(response.data.response.blockeddomains);
            }
            if(response.data.response.allowedemaildomains){
              setAllowedDomains(response.data.response.allowedemaildomains.split(","));

            }
            } else {
              setMessage(response.data.response.message);
            }
            // Handle the response from the server
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle the error
          });
      //  }
  }

  useEffect(()=>{
console.log("Props are", props)
 languageRef.current = navigator.language.split('-')[0];
 console.log(languageRef.current);
initializeWidget(props.apiKey);
  
  },[props.apiKey])


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      requestVerificationEmail();
    }
}
const handleEmailFocus = () => {
  setMessage('');
};
  const requestVerificationEmail = async () => {
    try {
      setInProgress(true);
      setemailnotValid(false);
      const sanitizedEmail = sanitizeEmail(email);
      const emailValidObj = validateEmail(sanitizedEmail);
      console.log(emailValidObj.isValid);
      if (!emailValidObj.isValid) {
        // Display an error message or prevent further processing
        console.error(emailValidObj.message);
        setemailnotValid(true);
        setMessage(emailValidObj.message);
        setInProgress(false);
        
        return;
      }
      console.log("about to set cookies ", email)
      const days= 30/1440;
      Cookies.set('user', email, {
        expires:days,
        secure: true,
        sameSite: 'None',
        domain: '.checkid.ai'
      });
      console.log(Cookies.get('user'));


      const response = await axios.post(ENDPOINT + '/send-verification-link', { "email" : email, "configuration": props.configuration,"referrer":props.referrer, "language":languageRef.current},{
        headers: {
          'Authorization': `Bearer ${props.apiKey}`,
          'clientuid': props.uid
        }
      });
      console.log(response)
      if(response.data && response.data.valid === true){


      const { uniqueId } = response.data;
      setUniqueId(uniqueId); // Store the unique ID received from the server
      setInProgress(false);
      setIcon(iconMap[response.data.icon]);
      setMessage(response.data.message);
     
      } else {
        setInProgress(false);
        setIcon(iconMap[response.data.icon]);
        setMessage(response.data.message);

      }
    
    } catch (error) {
      setInProgress(false);
      console.error('Error:', error);
    }
  
  };
//   console.log("Outside",props.props.email)






  useEffect(() => {
   if(props.email){setEmail(props.email)}
 if(uniqueId){
  const socket = socketIOClient(ENDPOINT,{
    withCredentials: true,
    transports: ['websocket', 'polling']
  });
    socket.on('connect', () => {

        console.log('Connected to server');
        socket.emit("registerUniqueId",{"uniqueId": uniqueId, "message": "Hello from client with uniquid"});


    socket.on('updateClients', (data) => {
      console.log(data.uniqueId);
      console.log(uniqueId);
      console.log(data);
      if (data.uniqueId === uniqueId) {
        // Process the update
        console.log(`Verification step: ${data.message}`);
        setIcon(iconMap[data.icon]);
        setMessage(data.message);
        console.log("Data is ", data);
        if (data && (data.verified === true)){
          setisValid(data.verified);
          
          console.log(data)
          if(data.redirect   && !data.samedevice && !data.webhook ){
            window.location.href = data.redirect;
            socket.disconnect();

          }
          else 
          {
          
            const triggerEvent = () => {
              const event = new CustomEvent('IdDataReady', { detail: { idData: data } });
              document.dispatchEvent(event);
              setInProgress(false);
              socket.disconnect();
          };
          if (document.visibilityState === 'visible') {
            // Page is visible, dispatch the event immediately
            triggerEvent();
        
          }
          else {
            // Page is not visible, wait until it becomes visible
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    triggerEvent();
                    document.removeEventListener('visibilitychange', handleVisibilityChange);
                }
            };
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }
        
        }

      } else if (data && data.verified === false){
        setIcon(iconMap[data.icon]);
          setMessage(data.message)
          socket.disconnect();
          setInProgress(false);
          setTimeout(function(){
            if(data.redirect && !data.samedevice) {
            window.location.href = data.redirect ;
            }
          },200);
        } else if (data && data.reset){
          setIcon(iconMap[data.icon]);
          setMessage(data.message)
          socket.disconnect();
          setInProgress(false);
         
         
        }
      }
    });
    });
  
    return () => {
      if (socket) {
        socket.off('verificationProgress');
      }
    };
  }
  }, [uniqueId,props,email]);

  return (
    <div className="idclient">
      {/* <h1>Email and ID Confirmation</h1> */}

{/* <img src={idLogo} style={{width:'100%'}} alt="Description" /> */}

{/* <img alt="" src={props.images.detectedImage}  className="webcam-container"/> */}
{/* <div className="image-checkmark-container">
        <FaCheck className="checkmark"/>
    </div> */}
  <div className="idclientbody"  style={props.color ? { color: props.color} : {}}>
    {props.title && <h4 style={{margin:'5px'}}>{props.title}</h4>}
    {(initialized === null) && <div class="widget-loader" style={{ borderTopColor: "gray"}}></div>
 }
    {initialized === true && !inProgress && isValid === null &&  !props.email && input && !icon && <input 
    className="widget-input" 
    type="email" 
    placeholder={emailMessagesRef.current.placeholder} 
    tabIndex="0"  
    aria-label="Enter email address and press Enter to verify"
    value={email} 
    onKeyDown={handleKeyPress} 
    onFocus = {handleEmailFocus}
    onChange={(e)=>{setEmail(e.target.value)}}/>}
    
    <div>
    {/* {icon &&  isValid === null && <p style={{color:'yellowgreen'}}>Verification in Progress</p>} */}

      
      {icon}

    </div>
    {/* {(emailnotValid || !initialized || inProgress || icon || isValid===true || isValid===false) &&   */}
    <div dangerouslySetInnerHTML={{ __html: message }} /> 
    {(icon || inProgress) && <div class="dots-loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>}
    {/* } */}
    {/* {uniqueId && <p>Reference Number: {uniqueId}</p>} */}
 
    
    {initialized=== true && !inProgress && isValid === null && !icon &&  <button 
    className="widget-button"
    tabIndex="0"  
    aria-label="Send Validation Email"
    style={{
      color: props.color || undefined,
      backgroundColor: props.backgroundColor || undefined,
      borderColor: props.backgroundColor || undefined
    }}
    
    onClick={requestVerificationEmail}>{emailMessagesRef.current.verify || "Verify"}</button> }

</div>
{/* 1 */}
{/* <GoogleReCaptcha
                    onVerify={verifyRecaptchaCallback}
                  /> */}



      
    </div>
  );
}

export default IDClient;