import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import '../css/index.css';
import {Button, Card,CardBody,CardText,CardTitle,CardSubtitle, Spinner} from 'reactstrap';
 import {BlockBlobClient} from '@azure/storage-blob';
//import {BarcodeScanner, EnumBarcodeFormat, EnumScaleUpMode, ImageSource} from 'dynamsoft-javascript-barcode';
import { CoreModule,EnumCapturedResultItemType} from 'dynamsoft-core';
import { DecodedBarcodesResult, EnumBarcodeFormat} from "dynamsoft-barcode-reader";
import { LicenseManager } from 'dynamsoft-license';
import CreatePassButton from './CreatePassButton';
import { isAndroid,isIOS } from 'react-device-detect';
import {
 CameraEnhancer,
 CameraView,
} from "dynamsoft-camera-enhancer";
import {
 CapturedResultReceiver,
 CaptureVisionRouter,
} from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter } from "dynamsoft-utility";
import TimerOverlay from './TimerOverlay';
import SSNInput from './SSNInput';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import Cookies from "js-cookie";



//import "https://cdn.jsdelivr.net/npm/dynamsoft-document-viewer@1.0.0/dist/ddv.css";










const DocumentScanner =  ({licenseData, setLicenseData, images,setImages,timeLeft,setTimeLeft, timeColor, setTimeColor,dl,setDl,messages,setMessages,color,setColor,backgroundColor,setBackgroundColor, logo, setLogo, currentUrl,setCurrentUrl,licenseInit, setLicenseInit,referrer,setReferrer}) => {
  
  const ENDPOINT = process.env.REACT_APP_API_URL;
  
  let navigate = useNavigate();
  const { uniqueId } = useParams();
  const [expired,setExpired]= useState(true);
  const [idScan, setIdScan]= useState(false);
  const idscanRef = useRef(false);
  const [idValid,setIdValid]= useState(null);
  const [invalidMessage, setInvalidMessage]=useState('');
  const [barcodeImage,setBarcodeImage] =useState(null);
  const [barcodeData, setBarcodeData] = useState(null);
  const [endSession, setEndSession]=useState(null);
  const addressVerifiedRef=useRef(null);
  const props = useRef(null);
  const [processCompleted, setProcessCompleted] = useState(false);
  const [initialized,setInitialized]=useState(false);
  const [showSSNInput, setShowSSNInput] = useState(false);
const ssnVerifiedRef = useRef(null);
const fullnameRef=useRef("");
const ssnRef =useRef("");
const sameDeviceRef = useRef(null);
const [showFirstImage, setShowFirstImage] = useState(true);
const redirectRef = useRef(null);
const deviceRef = useRef('');
const messageRef = useRef("");
const scanRef = useRef("");
const idDataRef = useRef(null);


// useEffect(() => {
  // Check if currentUrl is blank
//   if (currentUrl === '') {
//     // Set the currentUrl to the current window location
//     window.location.href= Cookies.get('url'); ;
//   }
// }, []);
useEffect(() => {
  if (isAndroid) {
    deviceRef.current = 'android';
  } else if (isIOS) {
    deviceRef.current = 'ios';
  } else {
    deviceRef.current = 'unknown';
  }

  console.log('Detected Device:', deviceRef.current);
}, []);

useEffect(() => {
  const timer = setTimeout(() => {
    setShowFirstImage(false);
  }, 2000); // 5000 milliseconds = 5 seconds

  // Cleanup the timer
  return () => clearTimeout(timer);
}, [initialized]);

// useEffect(() => {
//   const videoElement = document.querySelector('dce-component > div[slot="content"] > video');
//   const targetElement = document.querySelector('.barcodebody');
//   targetElement.style.display = 'none';
//   if (!videoElement || !targetElement) {
//     console.error('Required elements not found');
//     return;
//   }

//   const handleLoadedMetadata = () => {
//     console.log('Video metadata loaded');
//     targetElement.style.top = `${videoElement.clientHeight + 200}px`;
//     targetElement.style.display = 'block';
//   };

//   // Check if video metadata is already loaded
//   if (videoElement) {
//     handleLoadedMetadata();
//   } else {
//     // Add event listener for loadedmetadata
//     videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
//   }

//   // Cleanup function to remove event listener if necessary
//   return () => {
//     videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
//   };
// }, [initialized]);

// useEffect(() => {

//   console.log("layout")
//   const videoElement = document.querySelector('.dce-video-container');
//   const targetElement = document.querySelector('.barcodebody');

//   console.log('Video element:', videoElement); // Debugging line
//   if (videoElement) {

//     if (targetElement) {
     
//       targetElement.style.position = 'absolute';
//       targetElement.style.top = `${videoElement.clientHeight+75}px`;
      
      
//   }     
  
// }
// targetElement.style.display = `block`;




// }, [initialized]);

const DLAbbrDesMap = {
  'DCA': 'Jurisdiction-specific vehicle class',
  'DBA': 'Expiry Date',
  'DCS': 'Last Name',
  'DAC': 'First Name',
  'DBD': 'Issue Date',
  'DBB': 'Birth Date',
  'DBC': 'Gender',
  'DAY': 'Eye Color',
  'DAU': 'Height',
  'DAG': 'Street',
  'DAI': 'City',
  'DAJ': 'State',
  'DAK': 'Zip',
  'DAQ': 'License Number',
  'DCF': 'Document Discriminator',
  'DCG': 'Issue Country',
  'DAH': 'Street 2',
  'DAZ': 'Hair Color',
  'DCI': 'Place of birth',
  'DCJ': 'Audit information',
  'DCK': 'Inventory Control Number',
  'DBN': 'Alias / AKA Family Name',
  'DBG': 'Alias / AKA Given Name',
  'DBS': 'Alias / AKA Suffix Name',
  'DCU': 'Name Suffix',
  'DCE': 'Physical Description Weight Range',
  'DCL': 'Race / Ethnicity',
  'DCM': 'Standard vehicle classification',
  'DCN': 'Standard endorsement code',
  'DCO': 'Standard restriction code',
  'DCP': 'Jurisdiction-specific vehicle classification description',
  'DCQ': 'Jurisdiction-specific endorsement code description',
  'DCR': 'Jurisdiction-specific restriction code description',
  'DDA': 'Compliance Type',
  'DDB': 'Card Revision Date',
  'DDC': 'HazMat Endorsement Expiration Date',
  'DDD': 'Limited Duration Document Indicator',
  'DAW': 'Weight(pounds)',
  'DAX': 'Weight(kilograms)',
  'DDH': 'Under 18 Until',
  'DDI': 'Under 19 Until',
  'DDJ': 'Under 21 Until',
  'DDK': 'Organ Donor Indicator',
  'DDL': 'Veteran Indicator',
  // old standard
  'DAA': 'Customer Full Name',
  'DAB': 'Customer Last Name',
  'DAE': 'Name Suffix',
  'DAF': 'Name Prefix',
  'DAL': 'Residence Street Address1',
  'DAM': 'Residence Street Address2',
  'DAN': 'Residence City',
  'DAO': 'Residence Jurisdiction Code',
  'DAR': 'License Classification Code',
  'DAS': 'License Restriction Code',
  'DAT': 'License Endorsements Code',
  'DAV': 'Height in CM',
  'DBE': 'Issue Timestamp',
  'DBF': 'Number of Duplicates',
  'DBH': 'Organ Donor',
  'DBI': 'Non-Resident Indicator',
  'DBJ': 'Unique Customer Identifier',
  'DBK': 'Social Security Number',
  'DBM': 'Social Security Number',
  'DCH': 'Federal Commercial Vehicle Codes',
  'DBR': 'Name Suffix',
  'PAA': 'Permit Classification Code',
  'PAB': 'Permit Expiration Date',
  'PAC': 'Permit Identifier',
  'PAD': 'Permit IssueDate',
  'PAE': 'Permit Restriction Code',
  'PAF': 'Permit Endorsement Code',
  'ZVA': 'Court Restriction Code',
  'DAD': 'Middle Name'
};

const parseDriverLicense = async(txt) => {

  let lines = txt.split('\n');
  let abbrs = Object.keys(DLAbbrDesMap);
  let map = {};
  lines.forEach((line, i) => {
      let abbr;
      let content;
      if(i === 1){
          abbr = 'DAQ';
          content = line.substring(line.indexOf(abbr) + 3);
      }else{
          abbr = line.substring(0, 3);
          content = line.substring(3).trim();
      } 
      if(abbrs.includes(abbr)){
          map[abbr] = {
              description: DLAbbrDesMap[abbr],
              content: content
          };
      }
  });
  return map;
};


window.addEventListener('load', () => {
  // Select all video elements
  const videos = document.getElementsByTagName('video');

  // Iterate over the NodeList
  for (let i = 0; i < videos.length; i++) {
    // Clear out the height style property
    videos[i].style.height = '';
  }
});



async function uploadBlob(blobUrl,uploadUrl,uniqueId,verified, data, email){
  const response = await fetch(blobUrl);
  const blobData = await response.blob();
  const blockBlobClient = new BlockBlobClient(uploadUrl);
  const uploadOptions = {
    blobHTTPHeaders: {
        blobContentType: 'image/jpeg'
    },
};
if(!props.current.faceonly) {
  // alert(JSON.stringify(licenseData.ocr));
  // alert(JSON.stringify(data.ocr));
  if(data.ocr) {
  data.ocr.documentName = data.documentName
  uploadOptions.metadata = {iddata:JSON.stringify(data.ocr)};
  } else {
    uploadOptions.metadata = {iddata:JSON.stringify(data)};
  }
}

await blockBlobClient.uploadData(blobData,uploadOptions);

let tags = {};
if(email ) {
  tags.email = email.replace("@", "=");
}

tags.referenceid = uniqueId;
// tags.type = licenseData.documentName;
tags.verified = verified ? "yes":"no";
tags.addressverified = data.addressverified ? "yes":"no";
  await blockBlobClient.setTags(tags);


}


async function uploadDescriptorBlob(base64Descriptor, uploadUrl,uniqueId,verified, data,email) {
  // Convert Base64 string to buffer

  try {
    // Convert Base64 string to Uint8Array
    const binaryString = atob(base64Descriptor);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a BlockBlobClient
    const blockBlobClient = new BlockBlobClient(uploadUrl);

    // Upload the descriptor as binary data with content type and metadata
    const uploadOptions = {
      blobHTTPHeaders: {
        blobContentType: 'application/octet-stream' // Optional: Change content type if needed
      },
      metadata: {
        iddata: JSON.stringify(data)
      }
    };

    await blockBlobClient.uploadData(bytes, uploadOptions);

    console.log(`Uploaded descriptor for ${uniqueId}`);

    // Prepare tags
    let tags = {};
    if (email) {
      tags.email = email.replace("@", "=");
    }
    tags.referenceid = uniqueId;
    tags.verified = verified ? "yes" : "no";

    // Set tags
    await blockBlobClient.setTags(tags);
    console.log(`Tags set for ${uniqueId}`);

  } catch (error) {
    console.error('Error uploading descriptor blob:', error);
  }

}



  const verifyAddress = async (apiKey, uniqueId, idData) => {
    //alert("running address check" + JSON.stringify(idData));
    // Return the axios.post promise so that the caller can await it
    return axios.post(ENDPOINT + '/verifyaddress', {
      "uniqueid": uniqueId, 
    "full": fullnameRef.current,
      "a1": idData.DAG.content,
      "state": idData.DAJ.content,
      "postal": idData.DAK.content.substring(0, 5),
      "stateid": idData
  }, {
        headers: {
            'Authorization': `Bearer ${apiKey}`
        }
    })
    .then(response => {
        //alert(JSON.stringify(response.data));
        if (response.data) {

            if(response.data.response.address){

              setLicenseData((prevState) => ({
                ...prevState,
                ocr: {
                  ...prevState.ocr,
                  address: response.data.response.address
                },
                addressverified: true
              }));
              addressVerifiedRef.current = true;
                return true;
            }
            addressVerifiedRef.current = response.data.response;
            if(props.current.rejectaddress && !addressVerifiedRef.current){
              return false;
            }
            setLicenseData((prevState) => ({
              ...prevState,
              addressverified: addressVerifiedRef.current 
            }));
            return true;
        } else {
            addressVerifiedRef.current = false;

            if(props.current.rejectaddress){
            return false; // Properly return false if the response data is falsy
            } else
            setLicenseData((prevState) => ({
              ...prevState,
              addressverified: false
            }));
            return true;
        }
    })
    .catch(error => {
        // alert('verifyError:', error);
        setLicenseData((prevState) => ({
          ...prevState,
          addressverified: false
        }));
        addressVerifiedRef.current = false;


        if(props.current.rejectaddress){
          return false;
          } else
          {
          return true;
      }


    });
}




function normalizeDate(dateStr) {
  if (!dateStr) return null;
  if (dateStr.includes('-')) {
      // Already in "YYYY-MM-DD" format
      return dateStr;
  } else if (dateStr.length === 8) {
      // Convert "MMDDYYYY" to "YYYY-MM-DD"
      return `${dateStr.slice(4)}-${dateStr.slice(0, 2)}-${dateStr.slice(2, 4)}`;
  }
  return dateStr;
}

function normalizeAddress(address) {
  if (!address) return null;
  // Simplify address comparison by removing unit numbers and extra spaces
  return address.toUpperCase().replace(/[^A-Z0-9]/g, '').replace(/UNIT[0-9]*/g, '');
}

function normalizeGender(gender) {
  if (!gender) return null;
  if (gender === 'M' || gender === '1') return 'M';
  if (gender === 'F' || gender === '2') return 'F';
  return gender;
}

function normalizeHeight(height) {
  if (!height) return null;
  if (height.includes('cm')) {
      // Convert "cm" to inches (1 inch = 2.54 cm)
      return parseFloat(height) / 2.54;
  } else if (height.includes('IN')) {
      // Remove " IN" suffix
      return parseFloat(height);
  }
  return height;
}
function getMessageByKey(messages1, key) {

  const messageObject = messages1.find((message) => message.hasOwnProperty(key));
  return messageObject ? messageObject[key] : null;
}
function compareFields(field1, field2) {
  if (field1 === null || field2 === null) return 0;
  if (typeof field1 === 'string' && typeof field2 === 'string') {
      // Use SequenceMatcher-like approach to calculate similarity
      let matches = 0;
      const len1 = field1.length;
      const len2 = field2.length;
      const len = Math.min(len1, len2);
      for (let i = 0; i < len; i++) {
          if (field1[i] === field2[i]) {
              matches++;
          }
      }
      return matches / Math.max(len1, len2);
  } else if (field1 === field2) {
      return 1;
  } else {
      return 0;
  }
}

async function calculateProbabilityMatch(data1, data2) {

  const fieldsToCompare = [
      ["documentNumber", "DAQ.content"],
      ["surname", "DCS.content"],
      ["givenNames", "DAC.content"],
      ["dateOfIssue", "DBD.content"],
      ["dateOfBirth", "DBB.content"],
      ["dateOfExpiry", "DBA.content"],
      ["sex", "DBC.content"],
      ["addressStreet", "DAG.content"],
      ["addressCity", "DAI.content"],
      ["addressJurisdictionCode", "DAJ.content"],
      ["addressPostalCode", "DAK.content"]
  ];

  let totalSimilarity = 0;
  let fieldCount = fieldsToCompare.length;

  fieldsToCompare.forEach(([field1, field2]) => {
      const field2Path = field2.split(".");
      let value2 = data2;
      field2Path.forEach(key => {
          value2 = value2 ? value2[key] : null;
      });

      let value1 = data1[field1];
      
      if (field1.includes('date')) {
          value1 = normalizeDate(value1);
          value2 = normalizeDate(value2);
      } else if (field1.includes('address')) {
          value1 = normalizeAddress(value1);
          value2 = normalizeAddress(value2);
      } else if (field1.includes('sex')) {
          value1 = normalizeGender(value1);
          value2 = normalizeGender(value2);
      } else if (field1.includes('height')) {
          value1 = normalizeHeight(value1);
          value2 = normalizeHeight(value2);
      }

      const similarity = compareFields(value1, value2);
      totalSimilarity += similarity;
  });

  const probabilityMatch = totalSimilarity / fieldCount;

  if (probabilityMatch > 0.7) {


    const idData= {};
    idData.givenNames = data2.DAC ? data2.DAC.content : data1.givenNames;

    idData.surname = data2.DCS ? data2.DCS.content : data1.surname;

fullnameRef.current = idData.givenNames + " " + idData.surname;
idData.dateOfBirth = data2.DBB ? normalizeDate(data2.DBB.content) : data1.dateOfBirth;
idData.dateOfExpiry = data2.DBA ? normalizeDate(data2.DBA.content) : data1.dateOfExpiry;
idData.addressStreet = data2.DAG ? data2.DAG.content : data1.addressStreet;
idData.addressCity = data2.DAI ? data2.DAI.content : data1.addressCity;
idData.addressJurisdictionCode = data2.DAJ ? data2.DAJ.content : data1.addressJurisdictionCode;
idData.addressPostalCode = data2.DAK ? data2.DAK.content : data1.addressPostalCode;
idData.dateOfIssue = data2.DBD ? normalizeDate(data2.DBD.content) : data1.dateOfIssue;
idData.documentNumber = data2.DAQ ? data2.DAQ.content : data1.documentNumber;
idData.gender = data2.DBC ? (data2.DBC.content=="1"? "M" : "F") : data1.sex;
idData.height = data2.DAU ? data2.DAU.content : data1.height;

// alert(JSON.stringify(idData));



setLicenseData(prevState => {
  const updatedData = {
    ...prevState,
    ocr: {
      ...prevState.ocr,
      givenNames: idData.givenNames,
      surname: idData.surname,
      dateOfBirth: idData.dateOfBirth,
      dateOfExpiry: idData.dateOfExpiry,
      addressStreet: idData.addressStreet,
      addressCity: idData.addressCity,
      addressJurisdictionCode: idData.addressJurisdictionCode,
      addressPostalCode: idData.addressPostalCode,
      dateOfIssue: idData.dateOfIssue,
      documentNumber: idData.documentNumber,
      sex: idData.gender,
      height: idData.height
    }
  };

  // Update the ref as well
  idDataRef.current = updatedData;

  return updatedData;
});
  




      return true;
  }




  // alert(probabilityMatch);
  return probabilityMatch > 0.7
}

function handleRedirect(){
  window.location.href=referrer || redirectRef.current;
}


    useEffect(() => {
      window.history.pushState(null, null, window.location.pathname);
      window.onpopstate = () => {
        window.history.go(1);
      };
    }, []);

    useEffect(() => {

      const intervalId = setInterval(() => {
          setTimeLeft(prevTime => {
              if (prevTime <= 0) {
                  clearInterval(intervalId); // Stop the countdown
                  return 0;
              }
              return prevTime - 1000; // Decrease by one second
          });
          if(timeLeft>=60000){
            setTimeColor("green")
          }
          if(timeLeft < 60000 && timeLeft>=30000) {
            setTimeColor("yellow");
          }
          if(timeLeft < 30000){
            setTimeColor("red");
          }
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup on component unmount
    
  }, [setTimeLeft,setTimeColor,timeLeft]);
    useEffect(() => {

      const socket = socketIOClient(ENDPOINT,{
        withCredentials: true,
        transports: ['websocket', 'polling'],
          query: {uniqueId }
    });
    
    
      socket.on('connect', () => {
  
  
        console.log('Connected to server');
         socket.emit('message', 'Scanning barcode');
         socket.emit('GetScanProps', {uniqueId});
         socket.on('scanprops', (data) => {
          if (data.props){
            
            props.current=data.props;


          }
          // Handle the data as needed
        });



        socket.on('updateClients', (data) => {
          if (data.uniqueId === uniqueId) {
            // Process the update
            if (data && (data.verified === true)){
            
              sameDeviceRef.current = !data.webhook ? data.samedevice : false;
              const referrer= Cookies.get('referrer');
              if ( referrer) {
                redirectRef.current=referrer;
                Cookies.set('referrer', '', { expires: -1 });

              }
              else if (data.redirect  && data.samedevice) {
                redirectRef.current=data.redirect;
                // setRedirectUrl(data.redirect);
                socket.disconnect();
              }else
                {
              const event = new CustomEvent('IdDataReady', { detail : {idData : data} });
              document.dispatchEvent(event);
              socket.disconnect();
            }
          } else if (data && data.verified === false){
            sameDeviceRef.current = data.samedevice;
              socket.disconnect();
              // setTimeout(function(){
              //   if(data.redirect && data.samedevice) {
              //   window.location.href = data.redirect ;
              //   }
              // },10000);
            } else if (data && data.reset){
              socket.disconnect();

             
             
            }
          }
        });   
 



  
    if(idScan){
      socket.emit('LicenseScanned', {uniqueId });
      console.log("socket sent");
  } else {
    socket.emit('LicenseScanning', {uniqueId });
    console.log("socket sent");

  }
  if (processCompleted) {
    //alert("Process Completed");
  if(idScan && idValid){
    //alert("ID Verified");
    socket.emit('LicenseVerified', {uniqueId: uniqueId, data: licenseData });
    // setBarcodeData(null);
    // setBarcodeImage(null);
    // setImages(null);
    // setLicenseData(null);
    setTimeout(() => { setEndSession(true); }, 500);
  
  } else if (idScan && idValid === false){
    socket.emit('LicenseNotVerified', {uniqueId: uniqueId, data: licenseData,reason: invalidMessage });
    setBarcodeData(null);
    setBarcodeImage(null);
    setImages(null);
    setLicenseData(null);
    setTimeout(() => { setEndSession(true); }, 2000);
  }
  }
});
  
    }, [uniqueId, idScan,idValid,processCompleted]);

    useEffect(() => {
      // CoreModule.engineResourcePaths = {
      //   std: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.0.0/dist/",
      //   dip: "https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.0.30/dist/",
      //   core: "https://cdn.jsdelivr.net/npm/dynamsoft-core@3.0.33/dist/",
      //   license: "https://cdn.jsdelivr.net/npm/dynamsoft-license@3.0.40/dist/",
      //   cvr: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.0.32/dist/",
      //   dbr: "https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.0.21/dist/",
      //   dce: "https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.1/dist/"
      // };



     // BarcodeScanner.engineResourcePath="../node_modules/dynamsoft-javascript-barcode/dist/";
       //BarcodeScanner.license =dl.ds;
    //BarcodeScanner.engineResourcePath ="https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.32/dist/";
    console.log("PropsDL",dl);
  //   const license="DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyNTAzNTM1LVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMjUwMzUzNSIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOjc1Nzg3OTY5OH0="
  // LicenseManager.initLicense(license);
    //  LicenseManager.apply(license);
   
  




    

     
        const loadDynamsoft = async () => {
            try {



              if(!licenseInit){
            
//const license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyNTAzNTM1LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyNTAzNTM1Iiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2x0cy5keW5hbXNvZnQuY29tIiwiY2hlY2tDb2RlIjo3NjEyNTg5NDV9';
CoreModule.engineResourcePaths.core = "https://cdn.jsdelivr.net/npm/dynamsoft-core@3.0.33/dist/";
CoreModule.engineResourcePaths.cvr = "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.0.32/dist/";
CoreModule.engineResourcePaths.ddn = "https://cdn.jsdelivr.net/npm/dynamsoft-document-normalizer@2.0.20/dist/";
CoreModule.engineResourcePaths.license = "https://cdn.jsdelivr.net/npm/dynamsoft-license@3.0.40/dist/";
CoreModule.engineResourcePaths.dip = "https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.0.30/dist/";
CoreModule.engineResourcePaths.std = "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.0.0/dist/";
CoreModule.engineResourcePaths.dbr ="https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.0.21/dist/";
CoreModule.engineResourcePaths.dce= "https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.1/dist/";


//await LicenseManager.initLicense('DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyNTAzNTM1LTEwMjg1ODk4OSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMjUwMzUzNSIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi0xMTE4Mzg5OTU0fQ==',true)
 
await LicenseManager.initLicense('f0114uwAAAD/smRHQOrVzaVY/RuqoOiINZtHeBPeQLH0h+EAMbpe0l7EKLl/QQZx0U2/vQRb2KRNyaOoyhTMz46XtI5YrIWBX//U9rkb9/dilZe2X5+M9T6/+OirYoIgEUkADOVACnnrzbTsKq3+omj4anydF;f0068MgAAAAMd6aLIiS6S756zQ0axSnM7b468J2a81Di8bp0/8i68h9zGlu0MdzPPmPcCiGf4jMjeKE+hMBXPPerqh0ELM4c=')


                
                // Preload "BarcodeReader" module for reading barcodes. It will save time on the initial decoding by skipping the module loading.
                CoreModule.loadWasm(['DBR','DDN']).catch((ex) => {
                    let errMsg;
                    if (ex.message?.includes('network connection error')) {
                      errMsg =
                        'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
                    } else {
                      errMsg = ex.message || ex;
                    }
                    console.error(errMsg);
                  });
                setLicenseInit(true);
                }




              const cameraView = await CameraView.createInstance();
              const cameraEnhancer = await CameraEnhancer.createInstance(cameraView);
              // uiContainer.current.innerText = "";
            //  uiContainer.current.append(cameraView.getUIElement());
            
            await cameraView.setUIElement(document.getElementById('div-ui-container'))
         cameraEnhancer.setResolution({width:480*3 ,height:640*2});

               console.log("1");

               const router = await CaptureVisionRouter.createInstance();
               router.setInput(cameraEnhancer);
               const resultReceiver = new CapturedResultReceiver();
                // document.getElementById('lib-load').hidden = false;
           //    let scanner = await BarcodeScanner.createInstance();
             //  await scanner.updateRuntimeSettings("dense");


               
              //  let settings = await scanner.getRuntimeSettings();
              //  // Sets the barcode type to PDF417.
              //  settings.barcodeFormatIds = EnumBarcodeFormat.BF_PDF417;
              //  // Sets the scale-up mode.
              //  settings.scaleUpModes[0] = EnumScaleUpMode.SUM_LINEAR_INTERPOLATION;
              //  await scanner.updateRuntimeSettings(settings);
              //  scanner.setModeArgument("scaleUpModes", 0, "AcuteAngleWithXThreshold", "0");
              //  scanner.setModeArgument("scaleUpModes", 0, "ModuleSizeThreshold", "3");
              //  scanner.setModeArgument("scaleUpModes", 0, "TargetModuleSize", "8");
              //  await scanner.setResolution(960,1280);



              router.addResultReceiver(resultReceiver);


              const filter = new MultiFrameResultCrossFilter();
      filter.enableResultCrossVerification(
        EnumCapturedResultItemType.CRIT_BARCODE,
        true
      ); // Filter out unchecked barcodes.
      // Filter out duplicate barcodes within 3 seconds.
      filter.enableResultDeduplication(
        EnumCapturedResultItemType.CRIT_BARCODE,
        true
      );
      filter.setDuplicateForgetTime(
        EnumCapturedResultItemType.CRIT_BARCODE,
        3000
      );
     await router.addResultFilter(filter);
      console.log("3");



    resultReceiver.onCapturedResultReceived = (txt) => {
    
      if(!txt.items[1] || idscanRef.current) return;
      
      idscanRef.current=true;
                // Initial parsing and image preparation
parseDriverLicense(txt.items[1].text).then(result1 => {
  // alert(txt.items[1].text);
  // return;
  setIdScan(true);
  //  alert(JSON.stringify(result1));
  
  setBarcodeData(result1);

  document.getElementById('dce-scanarea').hidden = true;
  return new Promise((resolve, reject) => {
    
    txt.items[0].imageData.toCanvas().toBlob(blob => {
          const barcode = URL.createObjectURL(blob);
         
          setBarcodeImage(barcode);
          resolve({ barcode, result1 }); // Pass both barcode and result1 forward
      });
  });
})
.then(({ barcode, result1 }) => {
  // Check expiration date
  return calculateProbabilityMatch(licenseData.ocr,result1).then(isNotExpired => ({ isNotExpired, barcode, result1 })); 
})
.then(({ isNotExpired, barcode, result1 }) => {
  // Verify address
  if (!isNotExpired) {
    setInvalidMessage("barcodeverificationfailed");
    messageRef.current=getMessageByKey(messages,"barcodeverificationfailed");
      return Promise.reject('Expiration Verification failed');
  } else {
      return verifyAddress(props.current.apiKey, uniqueId, result1).then(match => ({ match, barcode, result1 }));
  }
})
.then(({ match, barcode, result1 }) => {
  // Show SSN input and wait for SSN verification
  let verified = match;

  if (!verified) {
    setInvalidMessage("addressverificationfailed");
    messageRef.current=getMessageByKey(messages,"addressverificationfailed");
      return Promise.reject('Address Verification failed');
  }
  if(!props.current.verifyssn){
    return Promise.resolve({ verified: true, barcode, result1 }); 
  }
  setShowSSNInput(true);
  return new Promise((resolve, reject) => {
      const checkSSNInterval = setInterval(() => {
          if (ssnVerifiedRef.current === true) { // Assuming ssnVerified gets updated elsewhere
           // alert("Verified");
              clearInterval(checkSSNInterval);
             result1.DBM= {"description": "Social Security Number", "content" :ssnRef.current};
              resolve({ verified: true, barcode, result1 }); // Passing along the verification state and previous data
          } else if (ssnVerifiedRef.current === false) {
              clearInterval(checkSSNInterval);
              setInvalidMessage("ssnverificationfailed");
              messageRef.current=getMessageByKey(messages,"ssnverificationfailed");
              reject(new Error('SSN verification failed'));
          }
          // Add a timeout as necessary
      }, 1000);
  });
})
.then(({ verified, barcode, result1 }) => {
  // If everything is verified, upload the data
  if (props.current.uploadUrls && verified) {
      const uploadUrls = props.current.uploadUrls;

      let uploadPromises=[];
      if(props.current.faceonly){
        uploadPromises = [
        uploadBlob(images.faceImage, uploadUrls[0], uniqueId, verified, idDataRef.current, props.current.email),
        // uploadDescriptorBlob(licenseData.descriptor, uploadUrls[3], uniqueId, true, licenseData.ocr)
        ];
      } else {
       uploadPromises = [
          uploadBlob(images.faceImage, uploadUrls[0], uniqueId, verified, idDataRef.current, props.current.email),
          uploadBlob(images.detectedImage, uploadUrls[1], uniqueId, verified,idDataRef.current),
          uploadBlob(barcode, uploadUrls[2], uniqueId, verified,result1),
          // uploadDescriptorBlob(licenseData.descriptor, uploadUrls[3], uniqueId, true, licenseData.ocr)
      ];
    }
      return Promise.all(uploadPromises);
  } else {

      return Promise.resolve(); // Skip uploads if not needed
  }
})
.then(() => {
  // Finalization code
  setIdValid(true);
  setProcessCompleted(true);
})
.catch(error => {
  // Error handling code
  // alert(error);
  // alert(JSON.stringify(error));
  if(!invalidMessage) setInvalidMessage("verificationfailed");
  messageRef.current=getMessageByKey(messages,"verificationfailed");
  setIdValid(false);
  console.error("Error during the license processing or file uploads:", error);
  setProcessCompleted(true); // Finalize the process in case of error as well
});



               }
              
             // scanner.barcodeStrokeStyleBeforeVerification = "orange";
            //   scanner.ifSaveOriginalImageInACanvas = true;
            // //  scanner.barcodeFillStyle = "rgba(red, green, blue, alpha)";
            //  await scanner.setUIElement(document.getElementById('div-ui-container'))
             
            //   await scanner.show();

            // document.getElementsByTagName('video')[0].style.height='auto';


            const settings = await router.getSimplifiedSettings('ReadDenseBarcodes');
            settings.barcodeSettings.expectedBarcodesCount = 1;
            isAndroid && (settings.barcodeSettings.grayscaleTransformationModes = [2]);
            settings.barcodeSettings.barcodeFormatIds = 0x02000000;
            settings.capturedResultItemTypes |= EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE;
            // settings.barcodeSettings.barcodeFormatIds =EnumBarcodeFormat.BF_PDF417;
            await router.updateSettings("ReadDenseBarcodes", settings);
      
      
      
          await cameraEnhancer.open();
          await router.startCapturing("ReadDenseBarcodes");


             setInitialized(true);

            } catch (ex) {
                //alert(ex.message);
                throw ex;
            }

        };
        loadDynamsoft();
        
        return () => {
   
        };
    }, []);







    return (
        <div >
   <TimerOverlay timeLeft={timeLeft} color={timeColor} timeColor={timeColor}  visible={false}/>

   {(!initialized) && <div className="session-starting">
        <img className = "logo" src={logo} alt="checkid"/>   
       <p><div className="init-loader"></div></p>
        <p style={{margin:'5px'}}>{getMessageByKey(messages,"initializescan")}</p>
      </div> } 


{timeLeft<=0 && (
  <div className="session-starting">
     <img className = "logo" src={logo} alt="checkid"/>  
   <p style={{margin:'5px'}}>{getMessageByKey(messages,"terminated")}</p> 
  {/* <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Start over" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}}  onClick={() => window.location.href = currentUrl}>Try again</Button></p> */}

  </div>
)}
{endSession && (

         <div className="session-starting">
         <img className = "logo" src={logo} alt="checkid"/>  
         {idValid? <p style={{margin:'5px'}}>{getMessageByKey(messages,"idverified")}</p>: <p style={{margin:'5px'}}>{getMessageByKey(messages,"idnotverified")}</p>}
         {messageRef.current && <p style={{margin:'5px'}}>{messageRef.current}</p>}
         {/* {!idValid && <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Start over" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}}  onClick={() => window.location.href = currentUrl}>Try again</Button></p>} */}

         {idValid? <p style={{margin:'5px'}}><CreatePassButton uniqueId={uniqueId} apiKey={props.current.apiKey} device={deviceRef.current} messages={messages} color={color} backgroundColor={backgroundColor}/></p>: ""}
         {/* {sameDeviceRef.current && <p><div className="init-loader"></div></p> } */}
         {/* {sameDeviceRef.current ? 
            <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Go to website" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={handleRedirect}>{getMessageByKey(messages,"samedevice")}</Button></p>
            :
            (sameDeviceRef.current===false && <p style={{ margin: '5px' }}>{getMessageByKey(messages,"differentdevice")}</p>)
            } */}

{(redirectRef.current || referrer) && <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Go to website" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={handleRedirect}>{getMessageByKey(messages,"samedevice")}</Button></p>} 
</div> 
)}


<div className="landscape-warning">
<img className = "logo" src={logo} alt="checkid"/>  
<p style={{margin:'5px'}}>{getMessageByKey(messages,"landscape")}</p>
</div>

{/* {idScan && !showSSNInput &&  !processCompleted && <p><div className="s-loader"></div></p> }       */}
{!barcodeImage && 
    <div   id="div-ui-container" style={{width:'100%',height:'100%'}}>
        <div class="dce-video-container" ></div>
        <div id="dce-scanarea" className="dce-scanarea">
                    <div className="dce-scanlight"></div>
                </div>
</div>}
{barcodeImage && <img alt="" src={barcodeImage}  className="webcam-container"/>}
<div className="scan-container">
<Card>
{/* {!idScan && <Spinner style={{position:'absolute',left:'38%', top:'5vh',height:'4em',width:'4em'}} color="secondary" type="border">
                  Loading...
                </Spinner>} */}

{showFirstImage &&  <img className="idCard" src="/id-back.png" alt="ID Back" />}

<CardBody className="barcodebody" >
    {/* <CardTitle tag="h3">
    {idScan ? ( idValid === false ? <p>This ID is not valid.</p> : <p>The ID is valid</p>) : <p>Scanning ID</p> }
    </CardTitle> */}
    {/* <CardSubtitle
      className="mb-2 text-muted"
      tag="h4"
    >
     Scan back of your ID
    </CardSubtitle> */}
    {scanRef.current && <p>{scanRef.current}</p>}
 {idScan && !showSSNInput &&  !processCompleted &&<p style={{display:'flex', justifyContent:"center"}}><div className="checkidloader"></div></p>}
    <CardTitle tag="h5">
    {showSSNInput && <SSNInput apiKey={props.current.apiKey} uniqueId={uniqueId} fullname={fullnameRef.current} messages={messages}  onVerified={(isVerified,ssn) => {ssnVerifiedRef.current=isVerified;ssnRef.current=ssn; setShowSSNInput(false); }} />}
    {!idScan ?  <p>Scanning ID Barcode</p>: !processCompleted ? (showSSNInput ? <p>{getMessageByKey(messages,"verifyssn")}</p> : <p>{getMessageByKey(messages,"verifyinformation")}</p>) : (idValid===true ? <p>{getMessageByKey(messages,"idverified")}</p>  : <p>{getMessageByKey(messages,"idnotverified")}</p>)}
    
    </CardTitle>
    {/* <Button className="startbutton" onClick={()=>navigate('/docscanner')}>
      Validate
    </Button> */}
    {/* {idScan && idValid ===false &&  <Button tabIndex="0"  aria-label="Start Over" className="startbutton" onClick={()=>navigate(`/webcapture/${uniqueId}`)}>
    {getMessageByKey(messages,"startover")}
    </Button>} */}
  </CardBody>
 
</Card>
</div>
<div><a className="start-over" href={currentUrl}>{getMessageByKey(messages,"startover")|| "Start over"}</a></div>
<div><img className="timer-image" src={logo} alt="checkid" /></div>
        </div>
    );
   










}


export  default DocumentScanner;