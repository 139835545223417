//  import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter,HashRouter, Routes, Route, Navigate} from 'react-router-dom';
import IDClient from './IDClient';
import QRClient from './QRClient';
import WebcamCapture from './WebcamCapture';
import DocumentCapture from './DocumentCapture';
import DocumentScanner from './DocumentScanner';
import { isMobile,isAndroid,isIOS } from 'react-device-detect';
import { FaCheck,FaRegTimesCircle } from "react-icons/fa";

function App(props) {
  const [initialized, setInitialized] = useSessionStorage('initialized', false);
  const [firstFaceDescriptor, setFirstFaceDescriptor] = useSessionStorage('firstFaceDescriptor', null);
  const [messages, setMessages] = useSessionStorage('messages', []);
  const [dl, setDl] = useSessionStorage('dl', null);
  const [timeLeft, setTimeLeft] = useSessionStorage('timeLeft', 600000);
  const [timeColor, setTimeColor] = useSessionStorage('timeColor', 'green');
  const [logo, setLogo] = useSessionStorage('logo', '/checkidfooter.png');
  const [color, setColor] = useSessionStorage('color', 'yellowgreen');
  const [backgroundColor, setBackgroundColor] = useSessionStorage('backgroundColor', 'white');
  const [currentUrl, setCurrentUrl] = useSessionStorage('currentUrl', '');
  const [licenseInit, setLicenseInit] = useState(false);
  const [images, setImages] = useSessionStorage('images', {
    originalImage: '',
    detectedImage: '',
    faceImage : ''
  });
  const [email, setEmail] = useSessionStorage('email', null);
  const [licenseData, setLicenseData] = useSessionStorage('licenseData', null);
  const [referrer, setReferrer] = useSessionStorage('referrer', null);
  


  function useSessionStorage(key, defaultValue) {
    const [state, setState] = useState(() => {
      const saved = sessionStorage.getItem(key);
      if (saved) {
        return JSON.parse(saved);
      }
      return defaultValue;
    });
  
    useEffect(() => {
      console.log('API URL:', process.env.REACT_APP_API_URL);
      sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
  
    return [state, setState];
  }



  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

useEffect(() => {
  if(!props.widget || props.openid){
    document.body.classList.add('custombody');
  }

  return () => {
    document.body.classList.remove('custombody');
  };
}, []);


const isMobileStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'darkgrey',
  color: 'yellowgreen',
  fontSize: 'x-large',
  zIndex: 2000,
  flexDirection: 'column'
};

const MobileOnlyAccessWarning = () => (
  <div style={isMobileStyle}>
    <img className = "logo" src="/checkid.PNG" alt="checkid"/>
    
    <p>To complete this verification you need a mobile device with a front and back facing camera.</p>
    {/* <div style={{color:'red', fontSize:'4em'}}><FaRegTimesCircle/></div> */}
  </div>
);


  const RouterComponent = (props.widget || props.reader )? HashRouter : BrowserRouter;
  // const isMobile = isMobileDevice();
  return (
    <RouterComponent>
    <Routes>
    {/* <Route path="/" element={<Navigate to="/qrcheck.html" />} />
    <Route path="/qrcheck.html"  element={<QRClient {...props}/>} /> */}
   { props.widget && <Route path="/" element={<IDClient {...props}/>}  />}
   { props.reader && <Route path="/" element={<QRClient {...props}/>}  />}
    {!props.widget && !props.reader && (
                    <>
  <Route path="/idclient"  element={<IDClient {...props}/>} ></Route> 
  <Route path="/qrclient"  element={<QRClient {...props}/>} ></Route> 
  
    <Route path="/webcapture/:uniqueId" element= {isMobile? <WebcamCapture initialized={initialized} setInitialized={setInitialized} firstFaceDescriptor={firstFaceDescriptor} setFirstFaceDescriptor={setFirstFaceDescriptor} images={images} setImages={setImages} timeLeft={timeLeft} setTimeLeft={setTimeLeft} timeColor={timeColor} setTimeColor={setTimeColor} dl={dl} setDl={setDl} email={email} setEmail={setEmail} messages={messages} setMessages={setMessages} color={color} backgroundColor={backgroundColor} setColor={setColor} setBackgroundColor={setBackgroundColor} logo={logo} setLogo={setLogo} currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} licenseInit={licenseInit} setLicenseInit={setLicenseInit} referrer={referrer} setReferrer={setReferrer}/> : <MobileOnlyAccessWarning />}/>
      <Route path="/doccapture/:uniqueId" element={isMobile? <DocumentCapture initialized={initialized} setInitialized={setInitialized} firstFaceDescriptor={firstFaceDescriptor} setFirstFaceDescriptor={setFirstFaceDescriptor} licenseData={licenseData} setLicenseData={setLicenseData} images={images} setImages={setImages} timeLeft={timeLeft} setTimeLeft={setTimeLeft}  timeColor={timeColor} setTimeColor={setTimeColor} dl={dl} setDl={setDl} email={email} setEmail={setEmail} messages={messages} setMessages={setMessages} color={color} backgroundColor={backgroundColor} setColor={setColor} setBackgroundColor={setBackgroundColor} logo={logo} setLogo={setLogo} currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} licenseInit={licenseInit} setLicenseInit={setLicenseInit} referrer={referrer} setReferrer={setReferrer} /> : <MobileOnlyAccessWarning />} />
      <Route path="/docscanner/:uniqueId" element={isMobile? <DocumentScanner licenseData={licenseData} setLicenseData={setLicenseData} images={images} setImages={setImages} timeLeft={timeLeft} setTimeLeft={setTimeLeft} timeColor={timeColor} setTimeColor={setTimeColor} dl={dl} setDl={setDl} email={email} setEmail={setEmail} messages={messages} setMessages={setMessages} color={color} backgroundColor={backgroundColor} setColor={setColor} setBackgroundColor={setBackgroundColor} logo={logo} setLogo={setLogo} currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} licenseInit={licenseInit} setLicenseInit={setLicenseInit} referrer={referrer} setReferrer={setReferrer} /> : <MobileOnlyAccessWarning />} />
      </>
                )}
    </Routes>
  </RouterComponent>
  
  );
 
}


export default App;